<template>
  <div class="dashboard">
    <div class="header">
      <div class="left">
        <section>
          <h3>{{ timeSign }}，{{ realname }}！</h3>
          <p>欢迎使用由英海瑞特科技研发系统，祝您工作愉快~</p>
        </section>
      </div>
      <div class="right">
        <section>
          <h3>车辆总数</h3>
          <p>{{ revenue.vehicleTotal || 0 }}</p>
        </section>
        <section>
          <h3>在线车辆</h3>
          <p>{{ revenue.vehicleOnline || 0 }}</p>
        </section>
        <section>
          <h3>在线率</h3>
          <p>{{ revenue.orderCount || 0 }}</p>
        </section>
      </div>
    </div>
    <div class="count">
      <div :class="['cont_box', 'cont_box_bg' + ++i]" v-for="(v, i) in contentArr" :key="i">
        <div class="left">
          <p class="theTitle">{{ v.title }}</p>
          <div class="right">
            <p class="theNumber">{{ v.number }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="item item1">
        <charts :option="item1Option" :id="item1"></charts>
      </div>
      <div class="item item2">
        <p class="type">
          <span>近7日班次实发率</span>
          <span>实发班次</span>
          <span>排班班次</span>
        </p>
        <div class="rate">
          <section>
            <h2>发车准点率</h2>
            <p>
              <span>{{ onTimeStartChance }}</span>
              <span>%</span>
            </p>
          </section>
          <section>
            <h2>到站准点率</h2>
            <p>
              <span>{{ onTimeArriveChance }}</span>
              <span>%</span>
            </p>
          </section>
          <section>
            <h2>车辆使用率</h2>
            <p>
              <span>{{ busUtilizationChance }}</span>
              <span>%</span>
            </p>
          </section>
        </div>
        <charts :option="item2Option" :id="item2"></charts>
      </div>
      <div class="item item3">
        <charts :option="item3Option" :id="item3"></charts>
      </div>
      <div class="item item4">
        <p class="title">新能源车耗</p>
        <div class="gauge">
          <img src="../../../assets/images/bus/gauge1.png" />
          <img src="../../../assets/images/bus/gauge2.png" srcset />
          <img src="../../../assets/images/bus/gauge3.png" srcset />
        </div>
        <div class="gaugeNum">
          <div>{{ item4Data.percentZero || 0 }}</div>
          <div>{{ item4Data.percentEleven || 0 }}</div>
          <div>{{ item4Data.percentTwentyOne || 0 }}</div>
        </div>
        <div class="gaugeCar">
          <div>0%~10%电量的车辆数</div>
          <div>11%~20%电量的车辆数</div>
          <div>21%~30%电量的车辆数</div>
        </div>
      </div>
      <div class="item item5">
        <p class="title">新能源汽车电量</p>
        <el-table height="32.5vh" stripe :data="item5Data" :cell-style="cellStyle">
          <el-table-column prop="type" label="状态"></el-table-column>
          <el-table-column prop="busCode" label="车牌号" :show-overflow-tooltip="true" width="90"></el-table-column>
          <el-table-column prop="electric" label="电量">
            <template slot-scope="scope">
              {{
              scope.row.electric + '%'
              }}
            </template>
          </el-table-column>
          <el-table-column prop="companyName" label="所属公司"></el-table-column>
          <el-table-column prop="lineName" label="线路" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="driverName" label="司机"></el-table-column>
          <el-table-column prop="phone" label="电话号码" :show-overflow-tooltip="true" width="120"></el-table-column>
        </el-table>
      </div>
      <div class="item item6">
        <p class="title">当日车辆报警</p>
        <el-table height="30vh" stripe :data="item6Data">
          <el-table-column prop="cph" label="报警车辆"></el-table-column>
          <el-table-column prop="driverName" label="司机姓名"></el-table-column>
          <el-table-column prop="address" label="报警位置" :show-overflow-tooltip="true" width="320"></el-table-column>
          <el-table-column prop="alarmType" label="报警类型" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="startTime" label="开始时间"></el-table-column>
          <el-table-column prop="endTime" label="结束时间"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import charts from '@/components/charts/charts.vue'
export default {
  components: {
    charts
  },
  data () {
    return {
      realname: null,
      revenue: {},
      item1: 'item1',
      item1Option: {
        title: {
          text: '线路营收排名(单位:千元)',
          padding: [0, 0, 0, 0],
          textStyle: {
            color: '#666666',
            fontSize: document.documentElement.clientHeight * 0.0188,
            fontWeight: 400
          }
        },
        grid: {
          top: '18%',
          left: '8%',
          right: '6%',
          bottom: '13%'
        },
        xAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              color: '#D9D9D9'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 10,
            interval: 0,
            textStyle: {
              color: '#666666',
              fontSize: 10
            }
          },
          data: ['2路', '3路', '1路', '11路']
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLabel: {
            margin: 10,
            textStyle: {
              color: '#666666',
              fontSize: 10
            }
          }
        },
        series: [
          {
            name: '销量',
            type: 'bar',
            barMaxWidth: '10',
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: true,
                position: 'top'
              }
            },
            itemStyle: {
              normal: {
                color: '#00B9FF'
              }
            },
            data: [123, 112, 98, 180]
          }
        ]
      },
      item2: 'item2',
      item2Option: {
        color: ['#00b9ff', '#ffcc31'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '42%',
          top: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            textStyle: {
              color: '#666666',
              fontSize: 10
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#D9D9D9'
            }
          },
          data: ['10/17', '10/17', '10/18']
        },

        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#666666',
              fontSize: 10
            }
          },
          data: []
        },
        series: [
          {
            name: '实发班次',
            type: 'bar',
            barMaxWidth: '10',
            data: [30, 18, 10]
          },
          {
            name: '排班班次',
            type: 'bar',
            barMaxWidth: '10',
            data: [20, 24, 35]
          }
        ]
      },
      item3: 'item3',
      item3Option: {
        title: {
          text: '报警数据',
          textStyle: {
            color: '#666666',
            fontSize: document.documentElement.clientHeight * 0.0188,
            fontWeight: 400
          }
        },
        grid: {
          top: '18%',
          left: '8%',
          right: '6%',
          bottom: '13%'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#ddd'
            }
          },
          // formatter: function (params) {
          //   let str = '2012-02-12 ' + params[0].name + ':00'
          //   let timestamp = new Date(str).getTime() - 60000 * 20
          //   return (
          //     params[0].seriesName +
          //     moment(timestamp).format('HH:mm') +
          //     '-' +
          //     params[0].name +
          //     '报警数' +
          //     params[0].value
          //   )
          // },
          formatter: '{a0}{b0}:<br />{c0}',
          backgroundColor: 'rgba(255,255,255,1)',
          padding: [5, 10],
          textStyle: {
            color: '#7588E4'
          },
          extraCssText: 'box-shadow: 0 0 5px rgba(0,0,0,0.3)'
        },
        legend: {
          x: 'right',
          data: ['昨日', '今日']
        },
        xAxis: {
          type: 'category',
          data: ['00:30', '03:30', '06:30'],
          boundaryGap: false,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 10,
            textStyle: {
              color: '#666666',
              fontSize: 10
            }
          }
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLabel: {
            margin: 10,
            textStyle: {
              color: '#666666',
              fontSize: 12
            }
          }
        },
        series: [
          {
            name: '今日',
            type: 'line',
            smooth: true,
            showSymbol: false,
            symbol: 'circle',
            symbolSize: 6,
            data: [12, 15, 18],
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(0, 185, 255, 0.4)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0, 185, 255, 0.05)'
                    }
                  ],
                  false
                )
              }
            },
            itemStyle: {
              normal: {
                color: '#00B9FF'
              }
            },
            lineStyle: {
              normal: {
                width: 1
              }
            }
          },
          {
            name: '昨日',
            type: 'line',
            smooth: true,
            showSymbol: false,
            symbol: 'circle',
            symbolSize: 6,
            data: [30, 15, 30],
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255, 204, 49, 0.4)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 204, 49, 0.05)'
                    }
                  ],
                  false
                )
              }
            },
            itemStyle: {
              normal: {
                color: '#FFCC31'
              }
            }
          }
        ]
      },
      item4Data: {},
      item5Data: [
        { type: '正在运行', busCode: '川A62422', electric: '48', companyName: '青川公司', lineName: '15路', driverName: '敬琼峰', phone: '17839174312' },
        { type: '充电中', busCode: '川A62422', electric: '48', companyName: '青川公司', lineName: '15路', driverName: '敬琼峰', phone: '17839174312' }
      ],
      item6Data: [
        { cph: '川A62422', driverName: '敬琼峰', address: '四川省广元市利州区盘龙镇小张家', alarmType: '未检测到驾驶员报警', startTime: '14:02', endTime: '14:19' },
        { cph: '川A62422', driverName: '敬琼峰', address: '四川省广元市利州区盘龙镇小张家', alarmType: '未检测到驾驶员报警', startTime: '14:02', endTime: '14:19' },
        { cph: '川A62422', driverName: '敬琼峰', address: '四川省广元市利州区盘龙镇小张家', alarmType: '未检测到驾驶员报警', startTime: '14:02', endTime: '14:19' },
        { cph: '川A62422', driverName: '敬琼峰', address: '四川省广元市利州区盘龙镇小张家', alarmType: '未检测到驾驶员报警', startTime: '14:02', endTime: '14:19' },
        { cph: '川A62422', driverName: '敬琼峰', address: '四川省广元市利州区盘龙镇小张家', alarmType: '未检测到驾驶员报警', startTime: '14:02', endTime: '14:19' }
      ],
      onTimeStartChance: 0,
      onTimeArriveChance: 0,
      busUtilizationChance: 0,
      percentOption: [
        { type: '前向碰撞', per: 0, color: '#88e282' },
        { type: '超速报警', per: 0, color: '#4989ff' },
        { type: '车道偏离报警', per: 0, color: '#3be5cd' },
        { type: '行人碰撞报警', per: 0, color: '#e5c236' },
        { type: '车距过近报警', per: 0, color: '#715cea' },
        { type: '其他', per: 0, color: '#ff4f61' }
      ],
      contentArr: [
        {
          icon: 'el-icon-tickets',
          bgcolor: '#fff',
          title: '当日ADAS报警',
          number: 0
        },
        {
          icon: 'el-icon-service',
          bgcolor: '#fff',
          // bgcolor: "#f1f1f1",
          title: '当月ADAS报警',
          number: 0
        },
        {
          icon: 'el-icon-bell',
          bgcolor: '#fff',
          title: '当日DSM报警',
          number: 0
        },
        {
          icon: 'el-icon-news',
          bgcolor: '#fff',
          title: '当月DSM报警',
          number: 0
        }
      ]
    }
  },
  methods: {
    cellStyle ({ row, column }) {
      let cellStyle;
      switch (row.type) {
        case '正在运行':
          cellStyle = 'color:#2DA641';
          break;
        case '充电中':
          cellStyle = 'color:#FAAB0C';
          break;
        case '维修中':
          cellStyle = 'color:#D40000';
          break;
        default:
          cellStyle = '';
      }
      if (column.label == '状态')
        return cellStyle;
    }
  },

  computed: {
    timeSign () {
      const timeSign = new Date().getHours()
      if (timeSign >= 6 && timeSign < 11) {
        return '早上好'
      } else if (timeSign >= 11 && timeSign < 13) {
        return '中午好'
      } else if (timeSign >= 13 && timeSign < 18) {
        return '下午好'
      } else {
        return '晚上好'
      }
    }
  },
  filters: {
    incomeFilter (v) {
      return `¥ ${(v / 100).toFixed(2)}`
    }
  },
  created () {
    this.realname = sessionStorage.getItem('realname')
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  height: 100%;
  overflow: auto;
  @include themify() {
    .header {
      height: 8vh;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding: 20px;
      background: #fff;
      // margin-bottom: 20px;
      // align-items: center;
      .left {
        section {
          h3 {
            font-size: (32rem/14);
            font-weight: 400;
            color: themed('b');
            margin: 0 0 1vh 0;
          }
          p {
            font-size: (12rem/14);
            color: themed('n6');
            margin: 0;
          }
        }
      }
      .right {
        display: flex;
        flex-wrap: nowrap;
        section {
          margin-left: 5vw;
          text-align: left;
          h3 {
            font-size: 1rem;
            font-weight: normal;
            color: themed('n6');
            margin: 0 0 1vh 0;
          }
          p {
            font-size: (26rem/14);
            font-weight: 600;
            color: themed('n7');
            margin: 0;
          }
        }
      }
    }
    .count {
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      .cont_box_bg1 {
        background: url('../../../assets/images/bus/cont_box_bg1.png') no-repeat;
      }
      .cont_box_bg2 {
        background: url('../../../assets/images/bus/cont_box_bg2.png') no-repeat;
      }
      .cont_box_bg3 {
        background: url('../../../assets/images/bus/cont_box_bg3.png') no-repeat;
      }
      .cont_box_bg4 {
        background: url('../../../assets/images/bus/cont_box_bg4.png') no-repeat;
      }
      .cont_box {
        display: flex;
        height: 7vh;
        overflow: hidden;
        padding: 0 1vh;
        background-size: 100% 100%;
        flex: 1;
        color: #fff;
        &:not(:last-of-type) {
          margin-right: 1.5vh;
        }
        .left {
          height: 100%;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1.5;
          height: 100%;
          line-height: 20px;
          font-size: 20px;
          .theTitle {
            padding-left: 10px;
            font-size: 1.65vh;
          }
        }
        .right {
          font-size: 20px;
          padding-right: 9%;
          box-sizing: border-box;
          .theNumber {
            padding-left: 10px;
            font-size: 2vh;
            line-height: 3vh;
          }
        }
      }
    }
    .main {
      // flex: 1;
      height: 100vh;
      display: grid;
      grid-template-rows: 4fr 4fr 5fr;
      grid-auto-columns: 1fr 1fr 1fr;
      // grid-template-rows: calc((92vh - 120px) / 3);
      grid-template-areas:
        'a b c'
        'd e e'
        'f f f';
      grid-gap: 20px 20px;
      .title {
        color: #666666;
      }
      .item {
        background: #ffffff;
        box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
        padding: 1.5vh;
        border-radius: 4px;
        overflow: hidden;
        /deep/ .el-table .cell {
          line-height: 32px;
        }
      }
      .item1 {
        grid-area: a;
      }
      .item2 {
        grid-area: b;
        padding: 1.5vh;
        flex: 7;
        color: #666666;
        .type {
          display: flex;
          align-items: flex-end;
          span {
            position: relative;
            &:nth-of-type(1) {
              font-size: 2vh;
              flex: 1;
            }
            &:not(:nth-of-type(1)) {
              font-size: 1.65vh;
            }
            &:nth-of-type(2),
            &:nth-of-type(3) {
              margin-left: 2vh;
              &::before {
                display: block;
                content: '';
                height: 1vh;
                width: 1vh;
                position: absolute;
                top: 50%;
                left: -1.5vh;
                transform: translateY(-50%);
              }
            }
            &:nth-of-type(2) {
              &::before {
                background: #00b9ff;
              }
            }
            &:nth-of-type(3) {
              &::before {
                background: #ffcc31;
              }
            }
          }
        }
        .rate {
          display: flex;
          flex-wrap: nowrap;
          margin-bottom: 1vh;
          section {
            margin-right: 2vw;
            h2 {
              font-size: 1.5vh;
              margin-bottom: 1vh;
              opacity: 0.9;
              color: #999999;
            }
            p {
              span {
                &:nth-of-type(1) {
                  font-size: 3vh;
                  font-weight: bold;
                }
                &:nth-of-type(2) {
                  font-size: 1.5vh;
                }
              }
            }
          }
        }
      }
      .item3 {
        grid-area: c;
      }
      .item4 {
        grid-area: d;
        .gauge {
          width: 100%;
          height: 60%;
          display: flex;
          align-items: center;
          img {
            height: 10vh;
            flex: 1;
            width: 10%;
            text-align: center;
            &:not(:last-of-type):not(:first-of-type) {
              margin: 0 1.5vh;
            }
          }
        }
        .gaugeNum {
          width: 100%;
          display: flex;
          align-items: center;
          div {
            flex: 1;
            font-size: 18px;
            width: 10%;
            font-weight: bolder;
            color: #666666;
            text-align: center;
            &:not(:last-of-type):not(:first-of-type) {
              margin: 0 1.5vh;
            }
          }
        }
        .gaugeCar {
          width: 100%;
          height: 15%;
          display: flex;
          align-items: center;
          div {
            flex: 1;
            font-size: 10px;
            padding: 6px;
            width: 10%;
            color: #666666;
            text-align: center;
            &:not(:last-of-type):not(:first-of-type) {
              margin: 0 1.5vh;
            }
          }
        }
      }
      .item5 {
        grid-area: e;
      }
      .item6 {
        grid-area: f;
      }
    }
  }
}
</style>
